import { Col, DatePicker, Form, Radio, Row, Select } from "antd";
import React, { useEffect, forwardRef, useImperativeHandle } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "moment/locale/id";
import moment from "moment";
import SelectSystem from "../../../../layout/components/select/select-system";

const { Option } = Select;
const FormFilter = forwardRef(({ onFinish, values }, ref) => {
  const history = useHistory();
  const [form] = Form.useForm();

  useImperativeHandle(ref, () => ({
    submit: () => {
      form.submit();
    },
    resetForm: () => {
      form.resetFields();
    },
    hardResetForm: () => {
      form.resetFields();

      const searchParams = new URLSearchParams(window.location.search);
      const paramsToDelete = [];

      searchParams.forEach((value, key) => {
        if (key !== "page" && key !== "search") {
          paramsToDelete.push(key);
        }
      });

      paramsToDelete.forEach((key) => {
        searchParams.delete(key);
      });

      const queryString = searchParams.toString();
      const newUrl = queryString ? `?${queryString}` : "";

      // Navigate to the new URL
      history.push(newUrl, { replace: true });
    },
  }));

  const currentYear = moment().year();
  const startYear = 2020;

  const years = Array.from(
    { length: currentYear - startYear + 1 },
    (_, i) => startYear + i
  );

  useEffect(() => {
    if (values !== null) {
      form.setFieldsValue(values);
    }
  }, [values]);

  return (
    <Form onFinish={onFinish} layout="vertical" form={form} name="form">
      <Row gutter={[16, 16]}>
        {/* <Col span={24} lg={12}>
          <Form.Item name="type" label="Tipe" initialValue={"month"}>
            <Select>
              <Option value="month">Bulan</Option>
              <Option value="semester">Semester</Option>
              <Option value="quarter">Kuartal</Option>
            </Select>
          </Form.Item>
        </Col> */}
        <Col span={24} lg={12}>
          <Form.Item name="month" label="Bulan" initialValue={"all"}>
            <Select placeholder="Pilih Bulan">
              <Option key={"all"} value={"all"}>
                Semua Bulan
              </Option>
              {moment.months().map((month, index) => (
                <Option key={index + 1} value={index + 1}>
                  {month}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} lg={12}>
          <Form.Item name="year" label="Tahun" initialValue={"all"}>
            <Select placeholder="Pilih Tahun">
              <Option key={"all"} value={"all"}>
                Semua Tahun
              </Option>
              {years.map((year) => (
                <Option key={year} value={year}>
                  {year}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {/*  <Col span={24} lg={12}>
          <Form.Item name="product" label="Produk" initialValue={null}>
            <Select>
              <Option value={null}>Semua Produk</Option>
              <Option value={1}>Produk 1</Option>
              <Option value={2}>Produk 2</Option>
              <Option value={3}>Produk 3</Option>
              <Option value={4}>Produk 4</Option>
              <Option value={5}>Produk 5</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} lg={12}>
          <Form.Item name="regional" label="Regional" initialValue={null}>
            <Select>
              <Option value={null}>Semua Regional</Option>
              <Option value={1}>Regional 1</Option>
              <Option value={2}>Regional 2</Option>
              <Option value={3}>Regional 3</Option>
              <Option value={4}>Regional 4</Option>
              <Option value={5}>Regional 5</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} lg={12}>
          <Form.Item name="area" label="Area" initialValue={null}>
            <Select>
              <Option value={null}>Semua Area</Option>
              <Option value={1}>Area 1</Option>
              <Option value={2}>Area 2</Option>
              <Option value={3}>Area 3</Option>
              <Option value={4}>Area 4</Option>
              <Option value={5}>Area 5</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} lg={12}>
          <Form.Item name="cabang" label="Cabang" initialValue={null}>
            <Select>
              <Option value={null}>Semua Cabang</Option>
              <Option value={1}>Cabang 1</Option>
              <Option value={2}>Cabang 2</Option>
              <Option value={3}>Cabang 3</Option>
              <Option value={4}>Cabang 4</Option>
              <Option value={5}>Cabang 5</Option>
            </Select>
          </Form.Item>
        </Col> */}
        <Col span={24} lg={24}>
          <Form.Item name="system" label="Sistem" initialValue={"all"}>
            <SelectSystem
              additionalOptions={[{ name: "Semua Sistem", id: "all" }]}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});

export default FormFilter;
