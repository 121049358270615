import React, { useEffect, useState } from "react";
import { Card, Col, Row, Spin } from "antd";
import Chart from "react-apexcharts";
import { formatterNumber } from "../../../../utils/input-number";

export default function PotensiPencairanHolding(props) {
  const { dataSource, isLoading } = props;

  const [dataOverview, setDataOverview] = useState({
    series: [
      { name: "Hot", data: [] },
      { name: "Incoming", data: [] },
      { name: "Proses", data: [] },
      { name: "Akad", data: [] },
      { name: "Cair", data: [] },
    ],
    options: {
      chart: {
        type: "bar",
        height: 400,
        stacked: false,
      },
      plotOptions: {
        bar: {
          distributed: false,
          horizontal: false,
          borderRadius: 4,
          columnWidth: "60%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            fontSize: "12px",
          },
        },
      },
      colors: ["#ff0022", "#ff7512", "#0073e6", "#ffb822", "#00b000"],
      yaxis: {
        title: {
          text: "Plafon (Milliar)",
        },
        labels: {
          formatter: function (val) {
            return val.toLocaleString("id-ID") + " Milliar";
          },
        },
      },
      legend: {
        show: true,
        position: "top",
      },
      tooltip: {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const plafon = series[seriesIndex][dataPointIndex].toLocaleString(
            "id-ID",
            {
              minimumFractionDigits: 2,
            }
          );
          // Access noaData from separate state
          const noa = w.config.noaData[seriesIndex][dataPointIndex] || 0;
          return `<div style="padding:5px;"><strong>Plafon : ${plafon} Milliar</strong><br/>NoA: ${formatterNumber(
            noa
          )}</div>`;
        },
      },
    },
    noaData: [[], [], [], [], []], // Separate noaData array for each status
  });

  const processData = () => {
    const categories = dataSource?.system?.map((system) => system?.name) || [];

    const hotData = [];
    const incomingData = [];
    const prosesData = [];
    const akadData = [];
    const cairData = [];

    const hotNoa = [];
    const incomingNoa = [];
    const prosesNoa = [];
    const akadNoa = [];
    const cairNoa = [];

    dataSource?.system?.forEach((system) => {
      const systemData = system?.data || [];

      const hotItem = systemData.find((item) => item.status === 1);
      hotData.push(hotItem?.plafon / 1000000000 || 0);
      hotNoa.push(hotItem?.noa || 0);

      const incomingItem = systemData.find((item) => item.status === 2);
      incomingData.push(incomingItem?.plafon / 1000000000 || 0);
      incomingNoa.push(incomingItem?.noa || 0);

      const prosesItem = systemData.find((item) => item.status === 3);
      prosesData.push(prosesItem?.plafon / 1000000000 || 0);
      prosesNoa.push(prosesItem?.noa || 0);

      const akadItem = systemData.find((item) => item.status === 4);
      akadData.push(akadItem?.plafon / 1000000000 || 0);
      akadNoa.push(akadItem?.noa || 0);

      const cairItem = systemData.find((item) => item.status === 5);
      cairData.push(cairItem?.plafon / 1000000000 || 0);
      cairNoa.push(cairItem?.noa || 0);
    });

    setDataOverview({
      series: [
        { name: "Hot", data: hotData },
        { name: "Incoming", data: incomingData },
        { name: "Proses", data: prosesData },
        { name: "Akad", data: akadData },
        { name: "Cair", data: cairData },
      ],
      options: {
        ...dataOverview.options,
        xaxis: {
          ...dataOverview.options.xaxis,
          categories,
        },
      },
      noaData: [hotNoa, incomingNoa, prosesNoa, akadNoa, cairNoa],
    });
  };

  useEffect(() => {
    if (isLoading === false) {
      processData();
    }
  }, [isLoading]);

  return (
    <Card className="hp-mb-24">
      <h5>Potensi Pencairan Holding</h5>
      {isLoading ? (
        <Row justify="center">
          <Col>
            <Spin />
          </Col>
        </Row>
      ) : (
        <div id="chart">
          <Chart
            options={{ ...dataOverview.options, noaData: dataOverview.noaData }}
            series={dataOverview.series}
            type="bar"
            height="400px"
          />
        </div>
      )}
    </Card>
  );
}
