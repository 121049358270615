import React, { useEffect, useState } from "react";
import { Card } from "antd";
import Chart from "react-apexcharts";
import { formatterNumber } from "../../../../utils/input-number";

export default function PotensiPencairanHolding(props) {
  const { dataSource } = props;

  const [dataOverview, setDataOverview] = useState({
    series: [
      { name: "Potensi", data: [] },
      { name: "Pending", data: [] },
      { name: "Cair", data: [] },
    ],
    options: {
      chart: {
        type: "bar",
        height: 400,
        stacked: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 4,
          columnWidth: "60%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            fontSize: "12px",
          },
        },
      },
      colors: ["#8485a7", "#ff7512", "#00b000"],
      yaxis: {
        title: {
          text: "Plafon (Milliar)",
        },
        labels: {
          formatter: function (val) {
            return val.toLocaleString("id-ID") + " Milliar";
          },
        },
      },
      legend: {
        show: true,
        position: "top",
      },
      tooltip: {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const plafon = series[seriesIndex][dataPointIndex].toLocaleString(
            "id-ID",
            {
              minimumFractionDigits: 2,
            }
          );
          const noa = w.config.noaData[seriesIndex][dataPointIndex] || 0;
          return `<div style="padding:5px;"><strong>${plafon} Milliar</strong><br/>NoA: ${formatterNumber(
            noa
          )}</div>`;
        },
      },
    },
    noaData: [[], [], []], // Separate noaData for Potensi, Pending, and Cair
  });

  useEffect(() => {
    const categories = dataSource?.system?.map((system) => system?.name) || [];

    // Map the plafon and NoA data for Potensi, Pending, and Cair
    const potensiData = [];
    const potensiNoa = [];
    const pendingData = [];
    const pendingNoa = [];
    const cairData = [];
    const cairNoa = [];

    dataSource?.system?.forEach((system) => {
      const potensi = system.potensi || {};
      const pending = system.pending || {};
      const cair = system.cair || {};

      potensiData.push(
        potensi.plafon ? parseFloat(potensi.plafon) / 1000000000 : 0
      );
      potensiNoa.push(potensi.noa || 0);

      pendingData.push(
        pending.plafon ? parseFloat(pending.plafon) / 1000000000 : 0
      );
      pendingNoa.push(pending.noa || 0);

      cairData.push(cair.plafon ? parseFloat(cair.plafon) / 1000000000 : 0);
      cairNoa.push(cair.noa || 0);
    });

    setDataOverview({
      series: [
        { name: "Potensi", data: potensiData },
        { name: "Pending", data: pendingData },
        { name: "Cair", data: cairData },
      ],
      options: {
        ...dataOverview.options,
        xaxis: {
          ...dataOverview.options.xaxis,
          categories,
        },
      },
      noaData: [potensiNoa, pendingNoa, cairNoa], // Store NoA data separately
    });
  }, [dataSource]);

  return (
    <Card className="hp-mb-24">
      <h5>Potensi Pencairan Dealing</h5>
      <div id="chart">
        <Chart
          options={{ ...dataOverview.options, noaData: dataOverview.noaData }}
          series={dataOverview.series}
          type="bar"
          height="400px"
        />
      </div>
    </Card>
  );
}
