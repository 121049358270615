import { useEffect, useState } from "react";

import { Row, Col, Card } from "antd";

import PageTitle from "../../../layout/components/content/page-title";
import themeConfig from "../../../configs/themeConfig";
import BottomMenu from "../../../layout/components/bottom-menu";
import { Paper, Wallet } from "react-iconly";
import { get } from "../../../utils/service";
import { API_URL } from "../../../api/config";

import { useSelector } from "react-redux";
import PotensiPencairanHolding from "./component/potensi-pencairan";
import FilterTable from "../../../layout/components/button-filter";
import FormFilter from "./component/filter";
import { formatterNumber } from "../../../utils/input-number";
import PencairanHolding from "./component/pencairan-holding";

export default function Pages() {
  const params = new URLSearchParams();
  const theme = useSelector((state) => state.customise.theme);

  const [dataSource, setDataSource] = useState([]);
  const [dataHolding, setDataHolding] = useState([]);
  const [holdingLoading, setHoldingLoading] = useState(false);

  const [filterValue, setFilterValue] = useState(null);

  const getData = async () => {
    let filter = "";
    if (filterValue) {
      // Add month, year, and system to params if they aren't set to "all"
      if (filterValue.month !== "all") {
        filter += "&month=" + filterValue.month;
      }
      if (filterValue.year !== "all") {
        filter += "&year=" + filterValue.year;
      }
      if (filterValue.system !== "all") {
        filter += "&system_id=" + filterValue.system;
      }
    }
    const res = await get(`${API_URL.stats}?${filter}`);
    if (res.status === 200) {
      setDataSource(res.data.data);
    }
  };
  const getDataHolding = async () => {
    setHoldingLoading(true);
    let filter = "";
    if (filterValue) {
      // Add month, year, and system to params if they aren't set to "all"
      if (filterValue.month !== "all") {
        filter += "&month=" + filterValue.month;
      }
      if (filterValue.year !== "all") {
        filter += "&year=" + filterValue.year;
      }
      if (filterValue.system !== "all") {
        filter += "&system_id=" + filterValue.system;
      }
    }
    const res = await get(`${API_URL.stats}/holding?${filter}`);
    if (res.status === 200) {
      setDataHolding(res.data.data);
    }
    setHoldingLoading(false);
  };

  useEffect(() => {
    getData();
    getDataHolding();
    document.title = "Dashboard - " + themeConfig.appname;
  }, [filterValue]);

  // Device Size Handling
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  const handleFilter = (values) => {
    setFilterValue(values);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  return (
    <>
      <PageTitle pageTitle="Dashboard">
        <Row>
          <Col>
            <FilterTable
              isFiltered={filterValue !== null ? true : false}
              setFilterValue={(value) => setFilterValue(value)}
            >
              <FormFilter onFinish={handleFilter} values={filterValue} />
            </FilterTable>
          </Col>
        </Row>
      </PageTitle>

      <div className="hp-content-main">
        <Row gutter={[16, 16]} className="hp-mb-24">
          <Col span={24} lg={8}>
            <Card className="hp-dashboard-card">
              <div className="hp-d-flex hp-align-items-center">
                <div className="hp-bg-grey-4 hp-bg-dark-grey hp-text-color-grey-1 icon">
                  <Paper size={24} />
                </div>
                <div>
                  <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-4">
                    Total NOA Potensi
                  </p>
                  <div className="hp-d-flex hp-align-items-center">
                    <h4 className="hp-mb-0">
                      {formatterNumber(dataSource?.total_noa_potensi)}
                    </h4>
                    <h6 className="hp-mb-0 hp-text-color-grey-1 hp-ml-4">
                      NOA
                    </h6>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col span={24} lg={8}>
            <Card className="hp-dashboard-card">
              <div className="hp-d-flex hp-align-items-center">
                <div className="hp-bg-orange-4 hp-bg-dark-orange hp-text-color-orange-1 icon">
                  <Paper size={24} />
                </div>
                <div>
                  <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-4">
                    Total NOA Pending
                  </p>
                  <div className="hp-d-flex hp-align-items-center">
                    <h4 className="hp-mb-0">
                      {formatterNumber(dataSource?.total_noa_pending)}
                    </h4>
                    <h6 className="hp-mb-0 hp-text-color-grey-1 hp-ml-4">
                      NOA
                    </h6>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col span={24} lg={8}>
            <Card className="hp-dashboard-card">
              <div className="hp-d-flex hp-align-items-center">
                <div className="hp-bg-success-4 hp-bg-dark-success hp-text-color-success-1 icon">
                  <Paper size={24} />
                </div>
                <div>
                  <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-4">
                    Total NOA Cair
                  </p>
                  <div className="hp-d-flex hp-align-items-center">
                    <h4 className="hp-mb-0">
                      {formatterNumber(dataSource?.total_noa_cair)}
                    </h4>
                    <h6 className="hp-mb-0 hp-text-color-grey-1 hp-ml-4">
                      NOA
                    </h6>
                  </div>
                </div>
              </div>
            </Card>
          </Col>

          <Col span={24} lg={8}>
            <Card className="hp-dashboard-card">
              <div className="hp-d-flex hp-align-items-center">
                <div className="hp-bg-grey-4 hp-bg-dark-grey hp-text-color-grey-1 icon">
                  <Wallet size={24} />
                </div>
                <div>
                  <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-4">
                    Total Potensi Cair
                  </p>
                  <h4 className="hp-mb-0">
                    Rp {formatterNumber(dataSource?.total_plafon_potensi)}
                  </h4>
                </div>
              </div>
            </Card>
          </Col>
          <Col span={24} lg={8}>
            <Card className="hp-dashboard-card">
              <div className="hp-d-flex hp-align-items-center">
                <div className="hp-bg-orange-4 hp-bg-dark-orange hp-text-color-orange-1 icon">
                  <Wallet size={24} />
                </div>
                <div>
                  <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-4">
                    Total Pending Cair
                  </p>
                  <h4 className="hp-mb-0">
                    Rp {formatterNumber(dataSource?.total_plafon_pending)}
                  </h4>
                </div>
              </div>
            </Card>
          </Col>
          <Col span={24} lg={8}>
            <Card className="hp-dashboard-card">
              <div className="hp-d-flex hp-align-items-center">
                <div className="hp-bg-success-4 hp-bg-dark-success hp-text-color-success-1 icon">
                  <Wallet size={24} />
                </div>
                <div>
                  <p className="hp-p1-body hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-4">
                    Total Cair
                  </p>
                  <h4 className="hp-mb-0">
                    Rp {formatterNumber(dataSource?.total_plafon_cair)}
                  </h4>
                </div>
              </div>
            </Card>
          </Col>
        </Row>

        <PotensiPencairanHolding dataSource={dataSource} />
        <PencairanHolding dataSource={dataHolding} isLoading={holdingLoading} />
        {/* <Card
          className="hp-border-color-black-40 hp-card-1 hp-upgradePlanCardOne hp-upgradePlanCardOne-bg"
          style={{
            backgroundImage: `url(${theme == "dark" ? cardImgDark : cardImg})`,
            backgroundSize: "cover",
            backgroundPosition: "right !important",
          }}
        >
          <Row align="middle" className="hp-mt-8">
            <Col span={24} className="hp-mb-4">
              <Row align="middle" justify="space-between">
                <Col flex="1">
                  <h4 className="hp-mb-8">
                    Selamat Datang di Datacenter Ekaakarjati
                  </h4>

                  <p className=" hp-mb-0">
                    Lihat update terbaru dari masing-masing sistem
                  </p>
                </Col>

                <Link to="/system/data">
                  <Button className="hp-float-right hp-mt-xs-16 hp-text-color-primary-1 hp-bg-dark-primary-1 hp-border-color-dark-primary-1 hp-text-color-dark-0">
                    Lihat System
                  </Button>
                </Link>
              </Row>
            </Col>
          </Row>
        </Card> */}
      </div>

      <BottomMenu active="home" />
    </>
  );
}
